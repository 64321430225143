<template>
  <div class="address grey-bg">
    <div class="address-con">
      <div class="address-form">
        <div class="address-item vertical-center vertical-center">
          <div class="address-text">收件人</div>
          <van-field class="address-inp" name="contact_name" type="text" v-model="contact_name" maxlength="20"
                 placeholder="请填写收货人姓名"/>
        </div>
        <div class="address-item vertical-center">
          <div class="address-text">手机</div>
          <van-field class="address-inp" name="contact_phone" type="tel"  v-model="contact_phone" maxlength="11"
                 placeholder="请填写收货人手机号"/>
        </div>
<!--        <div class="address-item vertical-center">
          <span class="address-text">邮箱</span>
          <input class="address-inp" name="contact_email" maxlength="30" v-model="contact_email" placeholder="请输入邮箱"
                 placeholder-class="address-inp-placeholder"/>
        </div>-->
        <div class="address-item vertical-center">
          <div class="address-text">所在地区</div>
          <div class="address-choice vertical-center" @click="addressChoice">
            <div class="address-choice-text no-choice" v-if=" province == '' ">请选择省市区、乡镇等</div>
            <div class="address-choice-text" v-else>{{ province }}-{{ city }}-{{ area }}</div>
          </div>
        </div>
        <div class="address-item vertical-center">
          <div class="address-text">详细地址</div>
          <van-field
              v-model="address"
              rows="1"
              autosize
              type="textarea"
              maxlength="50"
              placeholder="请填写详细地址" class="address-textarea"
          />
        </div>
        <div class="address-item vertical-center">
          <div class="address-text">邮编</div>
          <van-field class="address-inp" name="zip" maxlength="10" v-model="zip" type="number" placeholder="请填写邮编"
                 placeholder-class="address-inp-placeholder"/>
        </div>
        <div class="address-default space-between">
          <div class="address-text">设为默认</div>
          <van-switch class="address-switch" name="is_default" v-model="is_default" @change="defaultChange"
                      active-color="#22AC38" inactive-color="#B7BBBF" size="20px"/>
        </div>
      </div>
      <div class="btn-area">
        <button class="btn-submit" type="primary" @click="formSubmit">保存并使用</button>
      </div>
    </div>
    <div class="area-wrap">
      <van-popup v-model:show="address_show" position="bottom" get-container="body">
        <van-area  :area-list="areaList" :value="area_code"  @confirm="areaConfirm" @cancel="areaCancel" @change="areaChange" />
      </van-popup>
    </div>

  </div>
</template>

<script>
import areaList from '@/utils/areaList'
export default {
  name: "address-create",
  data() {
    return {
      contact_name: '',   //  姓名
      contact_phone: '',  //  手机
      contact_email: '',  //  邮箱
      province: '',       //  省
      city: '',           //  市
      area: '',           //  区
      address: '',        //  地址
      zip: '',            //  邮编
      is_default: false,  //  是否默认
      area_status: false, //  地址弹出框
      areaList: [],
      area_code: '',      // 地址选中code
      address_show: false,
      submit_status: true
    }
  },
  created() {
    this.areaList = areaList
  },
  mounted() {
    this.$nextTick(()=>{
      this.$util.domMinHeight()
    })
  },
  methods: {
    /* 提交按钮*/
    formSubmit() {
      let _this = this;
      if (this.contact_name.trim() == '') {
        this.$toast('请填写收货人姓名')
        return false
      } else if (this.contact_phone.trim() == '') {
        this.$toast('请填写收货人手机号');
        return false
      }else if (this.province == '') {
        this.$toast('请选择省市区');
        return false
      } else if (this.address.trim() == '') {
        this.$toast('请填写详细地址');
        return false
      }
      if(!this.submit_status){
        return false
      }
      this.submit_status = false;
      let data = {
        contact_name: this.contact_name.trim(),
        contact_phone: this.contact_phone.trim(),
        province: this.province,
        city: this.city,
        area: this.area,
        address: this.address.trim(),
        zip: this.zip,
        is_default: this.is_default
      }
      if(this.zip.trim() == '' || this.zip == null){
        delete data.zip
      }
      let url = this.$api.Address;
      this.$http.post(url, data, true).then(res => {
        if (res.data.success) {
          this.$toast('添加成功');
          setTimeout(() => {
            if(localStorage.getItem('address_f')){
              this.$router.replace({path: '/user/address',query:{type:'flea',f:this.$route.query.f}})
            }else {
              this.$router.push({path: '/user/address',query:{type:'flea',f:this.$route.query.f}})
            }
            this.submit_status = true;
          }, 2000)
        }
      }).catch(error=>{
        this.submit_status = true;
        if (error.response.status == 422) {
          this.$toast(error.response.data.message);
        }
      })
    },
    /*地址选择*/
    addressChoice() {
      this.address_show = true
    },
    /*地址确认*/
    areaConfirm(value){
      this.province = value[0].name;
      this.city = value[1].name;
      this.area = value[2].name;
      this.area_code = value[2].code;
      this.address_show = false
    },
    /*地址点击取消*/
    areaCancel(){
      this.address_show = false
    },
    /*地址选择时*/
    areaChange(value){
      this.province = value[0].name;
      this.city = value[1].name;
      this.area = value[2].name;
      this.area_code = value[2].code
    },
    defaultChange(){

    }
  }
}
</script>

<style lang="scss" scoped>
@import "~assets/scss/user/address/create.scss";
</style>
<style>
 .van-ellipsis {
  font-size: 0.5973333333rem !important;
}
 .van-picker__confirm {
  color: #FF6B29 !important;
}

</style>